import React, { useEffect } from "react"
import {
    HashRouter,
    Routes,
    Route,
    Outlet,
    BrowserRouter as Router,
} from "react-router-dom"
// BrowserRouter,
import LoginPage from "./pages/LoginPage"
import BeanPage from "./pages/BeanPage"
import CommandPage from "./pages/CommandPage"
import AuthProvider from "./context/AuthContext"
import BeanProvider from "./context/BeanContext"
import { Flex } from "./components"
import "./index.css"
import Dashboard from "./pages/Dashboard"
import SharedBeanPage from "./pages/SharedBeanPage"
import ProfilePage from "./pages/ProfilePage"
import NamePage from "./pages/NamePage"
import HomePage from "./pages/HomePage"

// import useWindowDimensions from "./hooks/dimensions"

function App() {
    useEffect(() => {
        const url = window.location.href

        // Redirect if the URL does not include "/waitlist"
        if (!url.includes("/waitlist")) {
            window.location.replace(
                "https://apps.apple.com/gb/app/sundays/id6446004330"
            )
        }
    }, [])

    return (
        <Router>
            <AuthProvider>
                <BeanProvider>
                    <div id="website-container">
                        {/* <HomePage /> */}
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route
                                path="/waitlist/referral/:referralCode"
                                element={<HomePage />}
                            />
                            {/* <Route path="*" element={<HomePage />} /> */}
                            {/* <Route path="/home" element={<Dashboard />} />
                            <Route
                                path="/bean/:id"
                                element={<SharedBeanPage />}
                            />
                            <Route
                                path="/profile/:id"
                                element={<ProfilePage />}
                            />
                            <Route path="*" element={<LoginPage />} /> */}
                        </Routes>
                    </div>
                </BeanProvider>
            </AuthProvider>
        </Router>
    )
}

export default App
