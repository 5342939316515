import React, { useEffect, useState, useLayoutEffect } from "react"
import {
    useSearchParams,
    useNavigate,
    useLocation,
    useParams,
} from "react-router-dom"
import {
    Flex,
    Text,
    Input,
    Label,
    Span,
    Box,
    Button,
    Img,
    A,
} from "../components"
import styled from "styled-components"
import colors from "../colors"
import axios from "../axios"
// import { useParams } from "react-router-dom"

function HomePage() {
    const [alertVisible, setAlertVisible] = useState(false)
    const appLink = "https://apps.apple.com/us/app/sundays/id6446004330"
    const { referralCode } = useParams()

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(referralCode)
            setAlertVisible(true)
            // wait 3 seconds
            setTimeout(() => {
                goToAppStore()
            }, 3000)
        } catch (error) {
            console.log("Failed to copy text: ", error)
        }
    }

    const goToAppStore = async () => {
        window.location.replace(appLink)
    }

    useEffect(() => {
        // Set a timeout to hide the component after 5 seconds
        const timer = setTimeout(() => {
            setAlertVisible(false)
        }, 3000)

        // Cleanup - clear the timer if the component unmounts earlier
        return () => clearTimeout(timer)
    }, [alertVisible])

    return (
        <Flex
            alignItems="center"
            padding="20px 0"
            height="100%"
            flexDirection="column"
            // overflow="hidden"
        >
            <Flex flexDirection="column" alignItems="center">
                <LogoButton onClick={goToAppStore}>
                    <Text
                        fontWeight="600"
                        mt="0"
                        mb="0"
                        textAlign="bottom"
                        fontSize="22px"
                    >
                        Sundays
                    </Text>
                </LogoButton>
            </Flex>
            <Flex>
                <Text
                    fontWeight="500"
                    fontSize="16px"
                    textAlign="center"
                    verticalAlign="center"
                >
                    Your friends unfiltered <Span fontSize="24px">🤝</Span>
                </Text>
            </Flex>
            <Bottom>
                {referralCode && (
                    <Flex
                        flexDirection="column"
                        border="1px solid"
                        alignItems="center"
                        borderRadius="10px"
                        padding="12px"
                        backgroundColor="white"
                        mt="20px"
                        maxWidth="320px"
                    >
                        <Text
                            fontSize="15px"
                            m="0px 0px 10px 0px"
                            textAlign="center"
                            fontWeight="500"
                        >
                            Use your friend's referral code to boost your
                            waitlist spot!
                        </Text>
                        <ReferralCode onClick={copyToClipboard}>
                            <CopyToClipboard src="/copy-to-clipboard.png" />
                            <Span
                                fontWeight="500"
                                fontSize="20px"
                                color="white"
                                letterSpacing="4px"
                            >
                                {referralCode}
                            </Span>
                        </ReferralCode>
                    </Flex>
                )}
                <AppButtonContainer onClick={goToAppStore}>
                    <Text m="0" fontWeight="600" mt="10px" fontSize="15px">
                        Get early access
                    </Text>
                    <NiceImg src="app-store-logo.png" />
                </AppButtonContainer>
            </Bottom>
            {alertVisible && <DropDownAlert referralCode={referralCode} />}
        </Flex>
    )
}

const AppButtonContainer = styled(Flex)`
    align-items: center;
    flex-direction: column;
    padding: 30px;

    &:hover {
        ${() => `${NiceImg} {         box-shadow: none;
        transform: translate(3px, 3px); }`}
        cursor: pointer;
    }
`

const LogoButton = styled(Flex)`
    border-radius: 60px;
    background-color: #f8f275;
    padding: 8px 14px 8px 14px;
    align-items: center;
    margin-top: 20px;
    border: 1px solid;
    box-shadow: 3px 3px 0;
    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
        box-shadow: none;
        transform: translate(3px, 3px);
    }
`

const Bottom = styled(Flex)`
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    flex-direction: column;
    align-items: center;
    display: flex;
`

const NiceImg = styled(Img)`
    border-radius: 12px;
    box-shadow: 3px 3px 0;
    max-width: 370px;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 200px;
    background-color: white;
    border: 1px solid;
    padding: 10px 14px;
    margin-top: 6px;

    :hover {
        box-shadow: none;
        transform: translate(3px, 3px);
    }
`

const ReferralCode = styled(Flex)`
    border: 1px solid;
    border-radius: 10px;
    padding: 10px;
    background-color: #70b9ff;
    position: relative;
    box-shadow: 3px 3px 0;
    transition: all 0.25s ease;
    cursor: pointer;

    :hover {
        box-shadow: none;
        transform: translate(3px, 3px);
    }
`

const CopyToClipboard = styled(Img)`
    position: absolute;
    top: -10px;
    right: -14px;
    object-fit: contain;
    width: 26px;
`
const AppStoreButton = styled(Flex)`
    border: 1px solid;
    border-radius: 10px;
    padding: 12px 0 12px 12px;
    background-color: white;
    margin: 20px 10px 0 10px;
    box-shadow: 3px 3px 0;
    max-width: 370px;
    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
        box-shadow: none;
        transform: translate(3px, 3px);
    }
`

function DropDownAlert({ referralCode }) {
    return (
        <Alert className="drop-down-element">
            <Text
                m="0"
                fontWeight="500"
                fontSize="16px"
                whiteSpace="nowrap"
                textAlign="center"
            >
                {referralCode} copied to clipboard! 🎉
            </Text>
        </Alert>
    )
}

const Alert = styled(Flex)`
    border: 1px solid;
    border-radius: 10px;
    padding: 12px;
    background-color: #00c29a;
    box-shadow: 3px 3px 0;
    width: 320px;
    max-width: 370px;
    transition: all 0.25s ease;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 34px;
`

export default HomePage
