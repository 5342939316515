import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react"
import axios from "../axios"
import { useAuth } from "./AuthContext"

const BeanContext = createContext()

export default function BeanProvider({ children }) {
    const [friendsBeans, setFriendsBeans] = useState("")
    const [usersBean, setUsersBean] = useState("")
    const [usersRealBean, setUsersRealBean] = useState("")
    const [usersCurrentBean, setUsersCurrentBean] = useState("")
    const [nextBeanDate, setNextBeanDate] = useState("")
    const [loading, setLoading] = useState(false)
    const { authenticatedUser } = useAuth()

    useEffect(() => {
        async function getBeans() {
            if (authenticatedUser.number) {
                try {
                    const { data } = await axios.get(
                        `/bean?user=${authenticatedUser.number}`,
                        { withCredentials: true }
                    )
                    setUsersBean(data.newBean)
                    setUsersRealBean(data.newBean)
                    setFriendsBeans(data.friendsBeans)
                    setUsersCurrentBean(data.currentBean)
                    const nextBeanDateConverted = new Date(
                        Number(data.nextBeanDate)
                    ).toLocaleString()
                    setNextBeanDate(nextBeanDateConverted)
                    setLoading(false)
                } catch (error) {
                    console.log(error, "ERROR")
                    setLoading(false)
                }
            }
        }

        getBeans()
    }, [authenticatedUser])

    const memoedValue = useMemo(
        () => ({
            loading,
            friendsBeans,
            usersBean,
            usersRealBean,
            usersCurrentBean,
            nextBeanDate,
            setUsersBean,
            setUsersRealBean,
        }),
        [
            loading,
            friendsBeans,
            usersBean,
            usersRealBean,
            usersCurrentBean,
            nextBeanDate,
        ]
    )

    return (
        <BeanContext.Provider value={memoedValue}>
            {children}
        </BeanContext.Provider>
    )
}

export function useBean() {
    return useContext(BeanContext)
}
