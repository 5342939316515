import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "../axios"
import { Flex, Text, Input, Label, Span, Box, Button, Img } from "../components"
import NamePage from "../pages/NamePage"

const AuthContext = createContext()

export default function AuthProvider({ children }) {
    const queryParams = new URLSearchParams(useLocation().search)
    const user = queryParams.get("user")
    const [loadingInitial, setLoadingInitial] = useState(true)
    const [loginFailed, setLoginFailed] = useState(false)
    const [authenticatedUser, setAuthenticatedUser] = useState({})
    const [inVerifyMode, setInVerifyMode] = useState(false)

    const nav = useNavigate()

    // useEffect(() => {
    //     const authenticate = async () => {
    //         const path = window.location.href
    //         try {
    //             let API_URL = "/getLoggedInUser"

    //             axios
    //                 .get(API_URL, {
    //                     withCredentials: true,
    //                 })
    //                 .then((response) => {
    //                     if (response.status === 200 && response.data.success) {
    //                         setAuthenticatedUser({
    //                             number: response.data.number.slice(2),
    //                             name: response.data.name,
    //                         })
    //                         setLoadingInitial(false)
    //                         if (!path.includes("bean")) {
    //                             nav("/home")
    //                         }
    //                     } else {
    //                         setAuthenticatedUser({})
    //                         setLoginFailed(true)
    //                         setInVerifyMode(false) // so user can retry
    //                         // need to delete the cookie so we can only do a request if they have an untested stytch cookie
    //                         setLoadingInitial(false)
    //                         if (!path.includes("bean")) {
    //                             nav(`/${user ? `?user=${user}` : ""}`)
    //                         }
    //                     }
    //                 })
    //         } catch (err) {
    //             setLoadingInitial(false)

    //             console.error("Error authenticating magic link")
    //         }
    //     }

    //     // setInVerifyMode(true)
    //     authenticate()
    // }, [])

    // async function createLoginEmail(email) {
    //     let userEmail = email.toLowerCase()
    //     let res = await axios
    //         .get(
    //             `/createMagicLink?email=${userEmail}&business_dashboard=true`,
    //             {
    //                 withCredentials: true,
    //             }
    //         )
    //         .then((response) => {
    //             if (
    //                 response.status === 200 &&
    //                 response.data.response.status_code === 200
    //             ) {
    //                 setLoginFailed(false) // in case this was previously true and this is a retry
    //             }
    //         })
    //     return res
    // }

    async function logout() {
        let res = await axios.get("/signOutUser", { withCredentials: true })
        if (res.status === 200 && res.data.success) {
            setAuthenticatedUser({})
            setInVerifyMode(false)
            nav("/")
        }
    }

    const memoedValue = useMemo(
        () => ({
            authenticatedUser,
            loadingInitial,
            setAuthenticatedUser,
            loginFailed,
            inVerifyMode,
            logout,
        }),
        [authenticatedUser, setAuthenticatedUser, inVerifyMode, loadingInitial]
    )

    return (
        <AuthContext.Provider value={memoedValue}>
            {children}
        </AuthContext.Provider>
    )
}

function BeanSpinnerDontAsk() {
    return (
        <Flex alignItems="center" justifyContent="center" height="100vh">
            <Img
                src="./CheesyBean.png"
                maxWidth="100px"
                height="30px"
                className="rotating-bean-dont-ask"
            />
        </Flex>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}
