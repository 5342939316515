import React, { useEffect, useState, useLayoutEffect } from "react"
import {
    useSearchParams,
    useNavigate,
    useLocation,
    useParams,
} from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button, Img } from "../components"
import styled from "styled-components"
import colors from "../colors"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"

function fuck(str) {
    str = str.replace(/\s+/g, "")
    if (str.length > 2) {
        str = str.slice(0, 3) + " " + str.slice(3)
    }
    if (str.length > 6) {
        str = str.slice(0, 7) + " " + str.slice(7)
    }
    return str
}

function phoneFormat(input) {
    //returns (###) ###-####
    input = input.replace(/\D/g, "")
    var size = input.length
    if (size > 0) {
        input = "(" + input
    }
    if (size > 3) {
        input = input.slice(0, 4) + ") " + input.slice(4, 11)
    }
    if (size > 6) {
        input = input.slice(0, 9) + "-" + input.slice(9)
    }
    return input
}

function formatNumber(number) {
    return `(${number.slice(0, 3)})${number.slice(3, 6)}-${number.slice(6)}`
}

function LoginPage() {
    return (
        <Flex alignItems="center" flexDirection="column" padding="20px">
            <Flex
                borderRadius="25px"
                bg="#FDCE95"
                padding="10px"
                alignItems="center"
                mt="10px"
                mb="40px"
            >
                <Text
                    fontWeight="600"
                    mr="6px"
                    mt="0"
                    mb="0"
                    textAlign="bottom"
                >
                    Cheesy Bean
                </Text>
                <Img src="./cheesy-bean.svg" heihgt="100%" width="25px" />
            </Flex>
            <LoginComponent />
        </Flex>
    )
}

export const LoginComponent = () => {
    const [numberOne, setNumberOne] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [searchParams, setSearchParams] = useSearchParams()
    const [usersNumber, setUsersNumber] = useState(
        searchParams.get("user") ? searchParams.get("user") : ""
    )
    const [numberInParams, setNumberInParams] = useState(
        searchParams.get("user") ? searchParams.get("user") : false
    )
    const [phoneId, setPhoneId] = useState("")
    const [notAUser, setNotAUser] = useState(false)
    const [loading, setCodeLoading] = useState(false)
    const [loggedInUserLoading, setLoggedInUserLoading] = useState(false)
    const [sixDigitCodeError, setSixDigitCodeError] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const [codeSent, setCodeSent] = useState(false)
    const [codeToNumberCodeError, setCodeToNumberCodeError] = useState(false)
    const location = useLocation()
    const beanId = location.pathname.split("/bean/")[1]
    const nav = useNavigate()
    const {
        authenticatedUser,
        setAuthenticatedUser,
        loginFailed,
        inVerifyMode,
        loadingInitial,
    } = useAuth()

    function isValidPasscodeDigit(digitValue) {
        const regex = /^\d{6}$/gm
        if (digitValue.match(regex)) {
            return true
        }
        return false
    }

    function isValidNumber(number) {
        // Regex validates phone numbers in (xxx)xxx-xxxx, xxx-xxx-xxxx, xxxxxxxxxx, and xxx.xxx.xxxx format
        const regex = /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/g
        if (number.match(regex)) {
            return true
        }
        return false
    }

    async function handleSubmit(e) {
        if (e) e.preventDefault()
        setCodeLoading(true)
        try {
            const code = `${numberOne}`
            const resp = await axios({
                method: "post",
                url: `/authenticate`,
                data: {
                    code,
                    phoneId,
                },
            })
            if (resp.data.success) {
                setAuthenticatedUser({
                    name: resp.data.name,
                    number: resp.data.user.phone_numbers[0].phone_number.slice(
                        2
                    ),
                })
                if (!beanId) {
                    nav(`/home`)
                }
                setCodeLoading(false)
            } else {
                setErrorMessage("Not a good code :(")
                setNumberOne("")
                setSixDigitCodeError(true)
                setCodeLoading(false)
            }
        } catch (error) {
            setSixDigitCodeError(true)
            setCodeLoading(false)
        }
    }
    async function sendCode() {
        setCodeSent(true)
        try {
            const { data } = await axios.get(
                `/loginorcreate?user=${usersNumber.replace(/\D/g, "")}`,
                {
                    withCredentials: true,
                }
            )
            if (phoneNumberError) setPhoneNumberError(false)
            if (!data.error) {
                setPhoneId(data.phoneId)
                if (notAUser) {
                    setNotAUser(false)
                }
                setCodeToNumberCodeError(false)
                setCodeSent(false)
            } else {
                setCodeToNumberCodeError(true)
                setNotAUser(true)
                if (phoneId) {
                    setPhoneId(false)
                }
                setCodeSent(false)
            }
        } catch (error) {
            setCodeToNumberCodeError(true)
            setCodeSent(false)
            setPhoneNumberError(true)
            setNumberOne("")
        }
    }

    useLayoutEffect(() => {
        if (numberInParams && usersNumber && isValidNumber(usersNumber)) {
            sendCode()
        } else {
            console.log("not a valid number")
        }
    }, [])

    useEffect(() => {
        const number = usersNumber.replace(/\D/g, "")
        if (
            number &&
            number.length === 10 &&
            isValidNumber(number) &&
            !phoneId &&
            !numberInParams
        ) {
            sendCode()
        }
    }, [usersNumber])

    useEffect(() => {
        function handleOTP(e) {
            const input = document.querySelector(
                'input[autocomplete="one-time-code"]'
            )
            if (!input) return
            const ac = new AbortController()
            const form = input.closest("form")
            if (form) {
                form.addEventListener("submit", (e) => {
                    ac.abort()
                })
            }
            navigator.credentials
                .get({
                    otp: { transport: ["sms"] },
                    signal: ac.signal,
                })
                .then((otp) => {
                    input.value = otp.code
                    setNumberOne(otp.code)
                    if (form) form.submit()
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        if ("OTPCredential" in window) {
            // console.log("it's being added")
            window.addEventListener("DOMContentLoaded", handleOTP)
        }
        // console.log("rendering thingy me bob")
        return () => window.removeEventListener("DOMContentLoaded", handleOTP)
    }, [])

    useEffect(() => {
        if (
            numberOne &&
            numberOne.length === 6 &&
            isValidPasscodeDigit(numberOne)
        ) {
            handleSubmit()
        }
    }, [numberOne])
    return (
        <>
            <StyledForm onSubmit={handleSubmit}>
                {numberInParams || phoneId ? (
                    <>
                        <InputContainer>
                            <input
                                type="text"
                                inputMode="numeric"
                                autoComplete="one-time-code"
                                name="one-time-code"
                                value={numberOne}
                                className="six-digit-input"
                                onChange={(e) => {
                                    setNumberOne(e.target.value)
                                }}
                                placeholder="000000"
                                disabled={loading}
                                maxLength="6"
                            />
                        </InputContainer>
                        {sixDigitCodeError && (
                            <ErrorText>Code failed :(</ErrorText>
                        )}
                        {codeToNumberCodeError && (
                            <ErrorText>The code failed to send! :(</ErrorText>
                        )}
                    </>
                ) : (
                    <>
                        <Flex className="fade-in" flexDirection="column">
                            <InputContainer>
                                <NumberInput
                                    id="phoneNumberInput"
                                    maxLength="14"
                                    autoComplete="off"
                                    placeholder="(512) 123-4567"
                                    type="tel"
                                    name="phoneNumber"
                                    onChange={(e) => {
                                        setUsersNumber(e.target.value)
                                    }}
                                    value={phoneFormat(usersNumber)}
                                ></NumberInput>
                            </InputContainer>
                            {notAUser && (
                                <ErrorText>
                                    Sorry, you are not a user. Need to be
                                    invited! :(
                                </ErrorText>
                            )}
                            {phoneId && !phoneNumberError && (
                                <Text
                                    textAlign="center"
                                    fontSize="13px"
                                    color="#17181e"
                                    mt="15px"
                                >
                                    A code has been sent! :)
                                </Text>
                            )}
                            {phoneNumberError && (
                                <ErrorText>
                                    A code could not be sent right now :(
                                </ErrorText>
                            )}
                        </Flex>
                    </>
                )}
            </StyledForm>
            {(numberInParams || phoneId) && (
                <Flex
                    onClick={() => {
                        setPhoneId(false)
                        setNumberInParams(false)
                        setUsersNumber("")
                    }}
                >
                    <ActiveLetters color="#30313D">
                        Need another code?
                    </ActiveLetters>
                </Flex>
            )}
            {codeSent && (
                <Flex className="sliding-bean-baby-dont-ask" mt="20px">
                    A code is being sent
                </Flex>
            )}
            {!codeSent && phoneId && (
                <Flex className="sliding-bean-baby-dont-ask" mt="20px">
                    A code has been sent to{" "}
                    {usersNumber.includes(")")
                        ? usersNumber
                        : formatNumber(usersNumber)}
                    ! :)
                </Flex>
            )}
        </>
    )
}

const StyledForm = styled.form`
    display: flex;
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    padding: 30px 20px;
    background: #dfdfdf;
    width: 280px;
    box-sizing: border-box;
    height: ${({ open }) => (open ? "210px" : "110px")};
    flex-direction: column;
    transition: height 0.3s ease;
`

const InputContainer = styled(Box)`
    position: relative;
    height: 30px;
    width: 100%;
    display: flex;
`

const ActiveLetters = styled(Text)`
    &:hover {
        cursor: pointer;
    }
    color: #17181e;
`

const ErrorText = styled(Text)`
    font-size: 13px;
    color: #ff5450;
    text-align: center;
    margin: 17px 0 0 0;
`

const NumberInput = styled(Input)`
    font-size: 20px;
    width: 240px;
    box-sizing: border-box;
    height: 40px;
    border: none;
    display: flex;
    padding: 0 5px 0 17px;
    box-shadow: inset -1px -1px #ffffff, inset 0px 0px #0a0a0a,
        inset -2px -2px #dfdfdf, inset 2px 2px #808080;
    background-color: rgba(255, 255, 255, 0.3);
`

export default LoginPage
