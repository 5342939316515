import React, { useEffect, useState } from "react"
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import {
    Flex,
    Text,
    Input,
    Label,
    Span,
    Box,
    Button,
    TextArea,
} from "../components"
import styled from "styled-components"
import colors from "../colors"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"

// function formatNumber(number) {
//     return `(${number.slice(0, 3)})${number.slice(3, 6)}-${number.slice(6)}`
// }

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2)
    } else {
        usersNumber = number
    }
    return usersNumber
}

// function validateNumber(number) {
//     const match = number.match(/\d/g)
//     if (match && match.length === 10) {
//         return match.join("")
//     } else {
//         return false
//     }
// }

function BeanPage() {
    const [name, setName] = useState("")
    const [openedItem, setOpenedItem] = useState("")
    const [updateNameError, setUpdateNameError] = useState("")
    const [loading, setLoading] = useState(false)

    const { authenticatedUser, setAuthenticatedUser } = useAuth()

    async function addOrUpdateName(e) {
        e.preventDefault()
        try {
            const { data } = await axios.post(`/updateName`, {
                withCredentials: true,
                data: {
                    usersNumber: cleanNumber(authenticatedUser.number),
                    usersName: name,
                },
            })
            if (data.error) {
                setUpdateNameError(
                    "Failed to update your name. Please try again :("
                )
            } else {
                setAuthenticatedUser({
                    number: authenticatedUser.number,
                    name,
                })
            }
        } catch (error) {
            setUpdateNameError("Failed to update your name :(")
            console.log(error, "ERROR")
        }
    }

    return (
        <StyledForm onSubmit={addOrUpdateName}>
            <Text m="0 0 20px 0">
                Hello! Welcome to cheesy bean. We are a texting newsletter for
                bestest friends. Please enter a name so your friends can
                identify you! :)
            </Text>
            <StyledInput
                type="text"
                value={name}
                onChange={(e) => {
                    setName(e.target.value)
                }}
                placeholder="Jr. Jimmy Jones Jimmy Jenkins Jr. Jr. "
                disabled={loading}
            />
            <DeleteButton
                className="fade-in"
                type="submit"
                color={colors.lightGreen}
            >
                Save
            </DeleteButton>
            {updateNameError && <ErrorText>Updating name failed :(</ErrorText>}
        </StyledForm>
    )
}

export default BeanPage

const StyledForm = styled.form`
    display: flex;
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    padding: 20px 15px;
    background: #dfdfdf;
    width: 300px;
    box-sizing: border-box;
    flex-direction: column;
    transition: height 0.3s ease;
`

const StyledInput = styled(Input)`
    box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a,
        inset -2px -2px #dfdfdf, inset 2px 2px #808080;
    padding: 6px 8px;
    font-size: 16px;
`

const InputContainer = styled(Box)`
    position: relative;
    height: 30px;
    width: 100%;
    display: flex;
`

const ErrorText = styled(Text)`
    font-size: 13px;
    color: #ff5450;
    text-align: center;
    margin: 17px 0 0 0;
`

const ItemExpand = styled(Flex)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${({ opened }) =>
        opened ? colors.buttonBackgroundChosen : colors.buttonBackground};
    padding: 12px;
    margin: 8px 0;
    flex-direction: column;
    height: ${({ open }) => (open ? open : "48px")};
    transition: all 0.3s ease;
    ${({ bg }) => (bg ? `background-color: ${bg};` : "")}

    &:hover {
        cursor: pointer;
        background-color: ${({ bg }) =>
            bg ? bg : colors.buttonBackgroundChosen};
    }
`

const StyledTextArea = styled(TextArea)`
    transition: height 0.3s ease;
`

const DeleteButton = styled(Button)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${colors.buttonBackground};
    margin-top: 16px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;
    font-size: 18px;

    &:hover {
        cursor: pointer;
        background-color: ${colors.buttonBackgroundChosen};
    }
`
