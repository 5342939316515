import React, { useEffect, useState } from "react"
import {
    useSearchParams,
    useNavigate,
    Link,
    useLocation,
} from "react-router-dom"
import {
    Flex,
    Text,
    Input,
    Label,
    Span,
    Box,
    Button,
    TextArea,
    Img,
} from "../components"
import styled from "styled-components"
import colors from "../colors"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import { useBean } from "../context/BeanContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"
import { LoginComponent } from "./LoginPage"
import NamePage from "./NamePage"

function formatNumber(number) {
    return `(${number.slice(0, 3)})${number.slice(3, 6)}-${number.slice(6)}`
}

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2)
    } else {
        usersNumber = number
    }
    return usersNumber
}

function ProfilePage() {
    const [profile, setProfile] = useState("")
    const [profileLoading, setProfileLoading] = useState(true)
    const [friendStatus, setFriendStatus] = useState("")

    const location = useLocation()
    const profileId = location.pathname.split("/profile/")[1]
    const nav = useNavigate()

    const { authenticatedUser } = useAuth()
    const {
        loading,
        usersBean,
        friendsBeans,
        usersRealBean,
        usersCurrentBean,
        nextBeanDate,
        setUsersBean,
        setUsersRealBean,
    } = useBean()

    useEffect(() => {
        const getProfile = async () => {
            let url = `/profile/${profileId}`
            if (authenticatedUser.number) {
                // Need to get profile name, bean count, friend count
                url = `/profile/${profileId}?user=${authenticatedUser.number}`
            }
            try {
                const {
                    data: [profileData],
                } = await axios.get(url)
                setProfile(profileData)
                console.log(profileData, "PROFILE DATA")
                // const { friendCount, name, number } = profileData
                setProfileLoading(false)
            } catch (error) {
                console.log(error)
            }
        }
        getProfile()
    }, [authenticatedUser.number, authenticatedUser.name, friendStatus])

    // function addFriend() {

    // }

    // function acceptFriendRequest(e) {
    //     e.stopPropagation()
    //     async function acceptFriend() {
    //         try {
    //             const { data } = await axios.post(`/acceptFriendRequest`, {
    //                 withCredentials: true,
    //                 data: {
    //                     usersNumber: cleanNumber(authenticatedUser.number),
    //                     friendsNumber: beanOwner,
    //                 },
    //             })
    //             if (data.error) {
    //                 setAcceptFriendRequestError("Failed to accept friend")
    //             } else {
    //                 setFriendStatus("friends")
    //                 // setFriends(data.friends)
    //                 // setReceivedFriendRequests(data.friendRequestsReceived)
    //             }
    //         } catch (error) {
    //             setAcceptFriendRequestError("Failed to accept friend")
    //             console.log(error, "ERROR")
    //         }
    //     }

    //     acceptFriend()
    // }

    return (
        <Flex alignItems="center" flexDirection="column" padding="20px">
            <Flex
                borderRadius="25px"
                bg="#FDCE95"
                padding="10px"
                alignItems="center"
                mt="10px"
                mb="40px"
            >
                <Text
                    fontWeight="600"
                    mr="6px"
                    mt="0"
                    mb="0"
                    textAlign="bottom"
                >
                    Cheesy Bean
                </Text>
                <Img src="./cheesy-bean.svg" heihgt="100%" width="25px" />
            </Flex>
            {loading || profileLoading ? (
                <BeanSpinnerDontAsk />
            ) : (
                <>
                    <StyledBox>
                        <Text m="0" color={colors.wordsChosen} fontWeight={500}>
                            {profile.name ? profile.name : "Anonymous"}
                        </Text>
                    </StyledBox>
                    <StyledBox>
                        <Text m="0" color={colors.wordsChosen} fontWeight={500}>
                            {formatNumber(profile.number)}
                        </Text>
                        <Text m="0" color={colors.wordsChosen} fontWeight={500}>
                            Friend count: {profile.friendCount}
                        </Text>
                    </StyledBox>

                    {!authenticatedUser.number && (
                        <>
                            <Text
                                m="0 0 10px 0"
                                color={colors.wordsChosen}
                                fontWeight={500}
                            >
                                Login{"/"}Sign up to view 's whole bean!
                            </Text>
                            <LoginComponent />
                        </>
                    )}
                    {authenticatedUser.number && !authenticatedUser.name && (
                        <NamePage />
                    )}
                    {authenticatedUser.number && authenticatedUser.name && (
                        <>
                            <DeleteButton
                                onClick={(e) => {}}
                                color={colors.lightGreen}
                                fontWeight="600"
                                fontSize="20px"
                            >
                                Add as a friend
                            </DeleteButton>
                            <DeleteButton
                                onClick={() => nav(`/home`)}
                                fontWeight="600"
                                fontSize="20px"
                                color={colors.lightGreen}
                            >
                                Go home
                            </DeleteButton>
                        </>
                    )}
                </>
            )}
        </Flex>
    )
}

export default ProfilePage

const ItemExpand = styled(Flex)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${({ opened }) =>
        opened ? colors.buttonBackgroundChosen : colors.buttonBackground};
    padding: 12px;
    margin: 8px 0;
    flex-direction: column;
    height: ${({ open }) => (open ? open : "48px")};
    transition: all 0.3s ease;
    ${({ bg }) => (bg ? `background-color: ${bg};` : "")}

    &:hover {
        cursor: pointer;
        background-color: ${({ bg }) =>
            bg ? bg : colors.buttonBackgroundChosen};
    }
`
const StyledBox = styled(Flex)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${({ bg }) => (bg ? bg : colors.buttonBackground)};
    padding: 12px;
    margin: 8px 0;
    flex-direction: column;
`

const DeleteButton = styled(Button)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${colors.buttonBackground};
    margin-top: 16px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;

    &:hover {
        cursor: pointer;
        background-color: ${colors.buttonBackgroundChosen};
    }
`
