import React, { useEffect, useState } from "react"
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button } from "../components"
import styled from "styled-components"
import colors from "../colors"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"

function formatNumber(number) {
    return `(${number.slice(0, 3)})${number.slice(3, 6)}-${number.slice(6)}`
}

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2)
    } else {
        usersNumber = number
    }
    return usersNumber
}

function validateNumber(number) {
    const match = number.match(/\d/g)
    if (match && match.length === 10) {
        return match.join("")
    } else if (match && match.length === 11) {
        return match.slice(1).join("")
    } else {
        return false
    }
}

function FriendPage() {
    const [friends, setFriends] = useState([])
    const [receivedFriendRequests, setReceivedFriendRequests] = useState([])
    const [sentFriendRequests, setSentFriendRequests] = useState([])
    const [openedItem, setOpenedItem] = useState("")
    const [friendAddNumber, setFriendAddNumber] = useState("")
    const [loading, setLoading] = useState(false)
    const { authenticatedUser, loginFailed, inVerifyMode } = useAuth()
    const [addFriendError, setAddFriendError] = useState(false)
    const [deleteFriendError, setDeleteFriendError] = useState(false)
    const [getFriendsError, setGetFriendsError] = useState(false)
    const [acceptFriendRequestError, setAcceptFriendRequestError] =
        useState(false)
    const [denyFriendRequestError, setDenyFriendRequestError] = useState(false)
    const [deleteFriendRequestError, setDeleteFriendRequestError] =
        useState(false)
    const [addFriendLoading, setAddFriendLoading] = useState(false)

    useEffect(() => {
        async function getFriends() {
            if (authenticatedUser.number) {
                setLoading(true)
                try {
                    const { data } = await axios.get(
                        `/friends?user=${authenticatedUser.number}`,
                        { withCredentials: true }
                    )

                    setFriends(data.friends)
                    setReceivedFriendRequests(data.receivedFriendRequests)
                    setSentFriendRequests(data.sentFriendRequests)
                    setLoading(false)
                } catch (error) {
                    setGetFriendsError(true)
                    setLoading(false)
                }
            }
        }
        getFriends()
    }, [authenticatedUser.number])

    function deleteFriend(e, friendsNumber) {
        e.stopPropagation()
        async function removeFriend() {
            try {
                const { data } = await axios.delete(`/deleteFriend`, {
                    withCredentials: true,
                    data: {
                        usersNumber: cleanNumber(authenticatedUser.number),
                        friendsNumber,
                    },
                })
                if (data.error) {
                    setDeleteFriendError("Failed to delete friend")
                } else {
                    setFriends(data.friends)
                }
            } catch (error) {
                setDeleteFriendError("Failed to delete friend")
            }
        }

        removeFriend()
    }

    function deleteFriendRequest(e, friendsNumber) {
        e.stopPropagation()
        async function removeFriendRequest() {
            try {
                const { data } = await axios.delete(`/deleteFriendRequest`, {
                    withCredentials: true,
                    data: {
                        usersNumber: cleanNumber(authenticatedUser.number),
                        friendsNumber,
                    },
                })
                if (data.error) {
                    setDeleteFriendRequestError("Failed to delete friend")
                } else {
                    setSentFriendRequests(data.sentFriendRequests)
                }
            } catch (error) {
                setDeleteFriendError("Failed to delete friend request")
                console.log(error, "ERROR")
            }
        }

        removeFriendRequest()
    }

    function acceptFriendRequest(e, friendsNumber) {
        e.stopPropagation()
        async function acceptFriend() {
            try {
                const { data } = await axios.post(`/acceptFriendRequest`, {
                    withCredentials: true,
                    data: {
                        usersNumber: cleanNumber(authenticatedUser.number),
                        friendsNumber,
                    },
                })
                if (data.error) {
                    setAcceptFriendRequestError("Failed to accept friend")
                } else {
                    setOpenedItem(false)
                    setFriends(data.friends)
                    setReceivedFriendRequests(data.friendRequestsReceived)
                }
            } catch (error) {
                setAcceptFriendRequestError("Failed to accept friend")
                console.log(error, "ERROR")
            }
        }

        acceptFriend()
    }

    function denyFriendRequest(e, friendsNumber) {
        e.stopPropagation()
        async function denyFriend() {
            try {
                const { data } = await axios.post(`/denyFriendRequest`, {
                    withCredentials: true,
                    data: {
                        usersNumber: cleanNumber(authenticatedUser.number),
                        friendsNumber,
                    },
                })
                if (data.error) {
                    setDeleteFriendError("Failed to delete friend")
                } else {
                    setOpenedItem(false)
                    setReceivedFriendRequests(data.friendRequestsReceived)
                }
            } catch (error) {
                setDeleteFriendError("Failed to delete friend")
                console.log(error, "ERROR")
            }
        }

        denyFriend()
    }

    function addFriend(e) {
        e.stopPropagation()
        setAddFriendLoading(true)
        const friendsNumber = validateNumber(friendAddNumber)
        if (!friendsNumber) {
            setAddFriendError("Not a good number")
            setAddFriendLoading(false)
        } else {
            addFriendFunction()
        }

        async function addFriendFunction() {
            try {
                const { data } = await axios.post(`/addFriend`, {
                    withCredentials: true,
                    data: {
                        usersNumber: cleanNumber(authenticatedUser.number),
                        friendAddNumber: friendsNumber,
                    },
                })
                if (data.error) {
                    setAddFriendLoading(false)
                    setAddFriendError("Failed to add friend")
                } else {
                    setAddFriendLoading(false)
                    setOpenedItem(false)
                    setSentFriendRequests(data.friendRequestsSent)
                }
            } catch (error) {
                setAddFriendError("Failed to add friend")
                setAddFriendLoading(false)
            }
        }
    }

    return loading ? (
        <BeanSpinnerDontAsk />
    ) : (
        <Flex flexDirection="column" padding="10px 0" className="fade-in-long">
            <Text
                m="0"
                color={colors.wordsChosen}
                fontSize="20px"
                fontWeight="500"
            >
                Friends{"   "}
                <Span fontSize="16px" fontWeight="400">
                    ({friends.length})
                </Span>
            </Text>
            <Flex flexDirection="column">
                {friends.map((friend) => {
                    return (
                        <ItemExpand
                            opened={friend.friend === openedItem}
                            key={friend.friend}
                            onClick={() => {
                                console.log("haha")
                                if (openedItem !== friend.friend) {
                                    setOpenedItem(friend.friend)
                                } else {
                                    setOpenedItem("")
                                }
                            }}
                            open={friend.friend === openedItem && "100px"}
                        >
                            <Flex justifyContent="space-between">
                                <Text margin="0 6px 0 0">{friend.name}</Text>
                                <Text m="0">{formatNumber(friend.friend)}</Text>
                            </Flex>
                            {friend.friend === openedItem && (
                                <DeleteButton
                                    className="fade-in"
                                    onClick={(e) =>
                                        deleteFriend(e, friend.friend)
                                    }
                                    fontWeight="600"
                                    fontSize="16px"
                                >
                                    Delete friend
                                </DeleteButton>
                            )}
                        </ItemExpand>
                    )
                })}
                <ItemExpand
                    opened={"addFriend" === openedItem}
                    onClick={() => {
                        if (openedItem !== "addFriend") {
                            setOpenedItem("addFriend")
                        } else {
                            setOpenedItem("")
                        }
                    }}
                    bg={colors.lightGreen}
                    open={"addFriend" === openedItem && "140px"}
                >
                    <Flex flexDirection="column">
                        {"addFriend" !== openedItem ? (
                            <Text
                                m="0"
                                textAlign="center"
                                fontWeight="600"
                                fontSize="20px"
                            >
                                +
                            </Text>
                        ) : (
                            <Flex flexDirection="column" className="fade-in">
                                <Text m="0 0 6px 0">Add a friend</Text>
                                <Input
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => {
                                        setFriendAddNumber(e.target.value)
                                    }}
                                    padding="6px"
                                    value={friendAddNumber}
                                    fontSize="16px"
                                    maxLength="18"
                                    placeholder="any phone number format!"
                                ></Input>
                                <DeleteButton
                                    onClick={(e) => addFriend(e)}
                                    color={colors.lightGreen}
                                    mb="12px"
                                    fw="600"
                                    fs="18px"
                                    disabled={addFriendLoading}
                                >
                                    {addFriendLoading
                                        ? "Sending.."
                                        : "Send friend request"}
                                </DeleteButton>
                            </Flex>
                        )}
                    </Flex>
                </ItemExpand>
            </Flex>
            <Text
                m="10px 0 0 0"
                color={colors.wordsChosen}
                fontSize="20px"
                fontWeight="500"
            >
                Friend requests received{"   "}
                <Span fontSize="16px" fontWeight="400">
                    ({receivedFriendRequests.length})
                </Span>
            </Text>
            <Flex flexDirection="column">
                {receivedFriendRequests.map((friend) => {
                    return (
                        <ItemExpand
                            open={friend.number === openedItem && "100px"}
                            opened={friend.number === openedItem}
                            key={friend.number}
                            onClick={() => {
                                console.log("haha")
                                if (openedItem !== friend.number) {
                                    setOpenedItem(friend.number)
                                } else {
                                    setOpenedItem("")
                                }
                            }}
                        >
                            <Flex justifyContent="space-between">
                                <Text margin="0 6px 0 0">{friend.name}</Text>
                                <Text m="0">{formatNumber(friend.number)}</Text>
                            </Flex>
                            {friend.number === openedItem && (
                                <Flex justifyContent="center">
                                    <DeleteButton
                                        className="fade-in"
                                        onClick={(e) =>
                                            acceptFriendRequest(
                                                e,
                                                friend.number
                                            )
                                        }
                                        mr="10px"
                                        color={colors.lightGreen}
                                        fontWeight="600"
                                        fontSize="20px"
                                    >
                                        Accept
                                    </DeleteButton>
                                    <DeleteButton
                                        onClick={(e) =>
                                            denyFriendRequest(e, friend.number)
                                        }
                                        fontWeight="600"
                                        fontSize="16px"
                                    >
                                        Deny
                                    </DeleteButton>
                                </Flex>
                            )}
                        </ItemExpand>
                    )
                })}
            </Flex>
            <Text
                m="10px 0 0 0"
                color={colors.wordsChosen}
                fontSize="20px"
                fontWeight="500"
            >
                Friend requests sent{"   "}
                <Span fontSize="16px" fontWeight="400">
                    ({sentFriendRequests.length})
                </Span>
            </Text>
            <Flex flexDirection="column">
                {sentFriendRequests.map((friend) => {
                    return (
                        <ItemExpand
                            open={friend.number === openedItem && "100px"}
                            opened={friend.number === openedItem}
                            key={friend.number}
                            onClick={() => {
                                console.log("haha")
                                if (openedItem !== friend.number) {
                                    setOpenedItem(friend.number)
                                } else {
                                    setOpenedItem("")
                                }
                            }}
                        >
                            <Flex justifyContent="space-between">
                                <Text margin="0 6px 0 0">{friend.name}</Text>
                                <Text m="0">{formatNumber(friend.number)}</Text>
                            </Flex>
                            {friend.number === openedItem && (
                                <DeleteButton
                                    className="fade-in"
                                    onClick={(e) =>
                                        deleteFriendRequest(e, friend.number)
                                    }
                                    fontWeight="600"
                                    fontSize="16px"
                                >
                                    Delete friend request
                                </DeleteButton>
                            )}
                        </ItemExpand>
                    )
                })}
            </Flex>
        </Flex>
    )
}

export default FriendPage

const ItemExpand = styled(Flex)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${({ opened }) =>
        opened ? colors.buttonBackgroundChosen : colors.buttonBackground};
    padding: 12px;
    margin: 8px 0;
    flex-direction: column;
    height: ${({ open }) => (open ? open : "48px")};
    transition: height 0.3s ease;
    ${({ bg }) => (bg ? `background-color: ${bg};` : "")}

    &:hover {
        cursor: pointer;
        background-color: ${({ bg }) =>
            bg ? bg : colors.buttonBackgroundChosen};
    }
`

const DeleteButton = styled(Button)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${colors.buttonBackground};
    margin-top: 16px;
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;
    ${({ fw }) => (fw ? `font-weight: ${fw};` : "")}
    ${({ fs }) => (fs ? `font-size: ${fs};` : "")}
    ${({ disabled }) => disabled && `opacity: 0.5; pointer-events: none;`}

    &:hover {
        cursor: pointer;
        background-color: ${colors.buttonBackgroundChosen};
    }
`

{
    /* <Link to="/command">Click here to see all of the commands</Link>
<Text>Hello here is your bean</Text>
<Text>{bean ? bean : "You have not sent in your bean yet!"}</Text>
<Text>Here is your friends bean</Text>
{console.log(friendsBeans)}
{friendsBeans &&
    Object.keys(friendsBeans).map((number, i) => (
        <Text key={i}>
            {friendsBeans[number].name}({number}) -{" "}
            {friendsBeans[number].bean}
        </Text>
    ))} */
}
