import React, { useEffect, useLayoutEffect, useState } from "react"
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import {
    Flex,
    Text,
    Input,
    Label,
    Span,
    Box,
    Button,
    TextArea,
} from "../components"
import styled from "styled-components"
import colors from "../colors"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import { useBean } from "../context/BeanContext"
import BeanSpinnerDontAsk from "../components/BeanSpinner"

function formatNumber(number) {
    return `(${number.slice(0, 3)})${number.slice(3, 6)}-${number.slice(6)}`
}

function cleanNumber(number) {
    let usersNumber = undefined
    if (number.startsWith(" ") || number.startsWith("+")) {
        usersNumber = number.slice(2).match(/\d/g).join("")
    } else {
        usersNumber = number
    }
    return usersNumber
}

function validateNumber(number) {
    const cleanedNumber = cleanNumber(number)
    const match = cleanedNumber.match(/\d/g)
    if (match && match.length === 10) {
        return match.join("")
    } else if (match && match.length === 11 && match[0] === "1") {
        return match.slice(1).join("")
    } else {
        return false
    }
}

function BeanPage() {
    const [openedItem, setOpenedItem] = useState("")
    const [updateBeanScreen, setUpdateBeanScreen] = useState(false)
    const [createBeanScreen, setCreateBeanScreen] = useState(false)
    const [beanShareNumber, setBeanShareNumber] = useState("")
    const [beanShareNumberError, setBeanShareNumberError] = useState("")
    const [updateOrCreateBeanError, setUpdateOrCreateBeanError] =
        useState(false)
    const [beanShareLoading, setBeanShareLoading] = useState(false)
    const [beanSharedList, setBeanSharedList] = useState([])
    // const [loading, setLoading] = useState(false)

    const { authenticatedUser } = useAuth()
    const {
        loading,
        usersBean,
        friendsBeans,
        usersRealBean,
        usersCurrentBean,
        nextBeanDate,
        setUsersBean,
        setUsersRealBean,
    } = useBean()

    function addOrUpdateBean(e) {
        e.stopPropagation()
        async function updateOrCreateBean() {
            if (!usersBean) {
                setUpdateOrCreateBeanError("Nothing entered")
            }
            try {
                const { data } = await axios.post(`/updateOrCreateBean`, {
                    withCredentials: true,
                    data: {
                        usersNumber: cleanNumber(authenticatedUser.number),
                        usersBean,
                    },
                })
                if (data.error) {
                    setUpdateOrCreateBeanError(
                        "Failed to update or create your bean :("
                    )
                } else {
                    setOpenedItem(false)
                    setUpdateBeanScreen(false)
                    setCreateBeanScreen(false)
                    setUsersBean(data.newBean)
                    setUsersRealBean(data.newBean)
                }
            } catch (error) {
                setUpdateOrCreateBeanError(
                    "Failed to update or create your bean :("
                )
                console.log(error, "ERROR")
            }
        }

        updateOrCreateBean()
    }

    async function sendBeanShare(e) {
        e.stopPropagation()
        setBeanShareLoading(true)
        const friendsNumber = validateNumber(beanShareNumber)
        if (!friendsNumber) {
            setBeanShareNumberError("Not a good number")
            setBeanShareLoading(false)
        } else {
            shareBeanWithFriend()
        }

        async function shareBeanWithFriend() {
            try {
                const { data } = await axios.post(`/shareBeanText`, {
                    withCredentials: true,
                    data: {
                        usersNumber: cleanNumber(authenticatedUser.number),
                        beanShareNumber: validateNumber(beanShareNumber),
                    },
                })
                if (data.error) {
                    setBeanShareLoading(false)
                    setBeanShareNumberError("Failed to add friend")
                    setBeanSharedList([
                        ...beanSharedList,
                        {
                            number: formatNumber(
                                validateNumber(beanShareNumber)
                            ),
                            message: data.error,
                        },
                    ])
                } else {
                    setBeanShareLoading(false)
                    setBeanSharedList([
                        ...beanSharedList,
                        {
                            number: formatNumber(
                                validateNumber(beanShareNumber)
                            ),
                            message: "Text sent!",
                        },
                    ])
                    // setOpenedItem(false)
                    // setSentFriendRequests(data.friendRequestsSent)
                }
            } catch (error) {
                setBeanShareLoading(false)
                setBeanShareNumberError("Failed to add friend")
                console.log(error, "ERROR")
            }
        }
    }

    return loading ? (
        <BeanSpinnerDontAsk />
    ) : (
        <Flex
            flexDirection="column"
            padding="10px 0"
            className="fade-in-long"
            maxWidth="310px"
        >
            <Text
                m="0"
                color={colors.wordsChosen}
                fontSize="20px"
                fontWeight="500"
            >
                Your new bean
            </Text>
            <Text m="0" color={colors.wordsChosen}>
                Will become active on Sunday
            </Text>
            <Text m="0" color={colors.wordsChosen}>
                {nextBeanDate}
            </Text>
            <ItemExpand
                opened={true}
                onClick={() => {
                    if (openedItem !== "addBean") {
                        setOpenedItem("addBean")
                    } else {
                        setOpenedItem("")
                    }
                }}
                open={updateBeanScreen || createBeanScreen ? "480px" : "340px"}
            >
                <Flex flexDirection="column">
                    <Flex flexDirection="column" className="fade-in">
                        {usersRealBean ? (
                            <Text m="0 0 6px 0">Update bean</Text>
                        ) : (
                            <Text m="0 0 6px 0">Create bean</Text>
                        )}
                        <StyledTextArea
                            id="bean-text-area"
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => setUsersBean(e.target.value)}
                            padding="6px"
                            value={usersBean ? usersBean : ""}
                            fontSize="14px"
                            disabled={
                                !usersRealBean || updateBeanScreen
                                    ? false
                                    : true
                            }
                            height={
                                updateBeanScreen || createBeanScreen
                                    ? "340px"
                                    : "240px"
                            }
                            placeholder={
                                updateOrCreateBeanError
                                    ? "Please enter your awesome bean here! :)"
                                    : "Oh my gosh I found out Jenny had two boyfriends!!! both named Jackson Gray! ...."
                            }
                        ></StyledTextArea>
                        {usersRealBean ? (
                            <>
                                <DeleteButton
                                    onClick={(e) => {
                                        if (!updateBeanScreen) {
                                            setUpdateBeanScreen(true)
                                        } else {
                                            addOrUpdateBean(e)
                                        }
                                    }}
                                    fw="600"
                                    fs="18px"
                                    color={colors.lightGreen}
                                >
                                    Update your bean
                                </DeleteButton>
                                {updateBeanScreen && (
                                    <DeleteButton
                                        onClick={(e) => {
                                            if (updateBeanScreen) {
                                                setUpdateBeanScreen(false)
                                            }
                                        }}
                                        fw="600"
                                        fs="18px"
                                        mt="10px"
                                    >
                                        Cancel update
                                    </DeleteButton>
                                )}
                            </>
                        ) : (
                            <DeleteButton
                                onClick={(e) => {
                                    addOrUpdateBean(e)
                                }}
                                color={colors.lightGreen}
                                fw="600"
                                fs="18px"
                                mt="6px"
                                disabled={usersBean ? false : true}
                            >
                                Create your bean
                            </DeleteButton>
                        )}
                    </Flex>
                </Flex>
            </ItemExpand>
            <Text
                m="14px 0 6px 0"
                color={colors.wordsChosen}
                fontSize="20px"
                fontWeight="500"
            >
                Your active bean
            </Text>
            {usersCurrentBean && (
                <ItemExpand
                    opened={true}
                    onClick={() => {
                        if (openedItem !== "beanShare") {
                            setOpenedItem("beanShare")
                        } else {
                            setOpenedItem("")
                        }
                    }}
                    open={
                        openedItem === "beanShare"
                            ? `${beanSharedList.length * 50 + 360}px`
                            : "70px"
                    }
                    bg={colors.lightGreen}
                >
                    <Text m="0 0 6px 0" fontSize="16px" fontWeight="500">
                        Share your bean directly to a non-Cheesy bean using
                        friend
                    </Text>
                    {openedItem === "beanShare" && (
                        <>
                            <Text m="0 0 6px 0">
                                This will send your favorite peep a texted link
                                to your active bean/friend request. They will
                                have to login and accept your friend request to
                                view, verified by their phone number. This way
                                your bean link cannot be shared to unwanted
                                others.
                            </Text>
                            <Input
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                    setBeanShareNumber(
                                        cleanNumber(e.target.value)
                                    )
                                }}
                                padding="6px"
                                value={beanShareNumber}
                                fontSize="16px"
                                maxLength="18"
                                placeholder="any phone number format!"
                            ></Input>
                            <DeleteButton
                                disabled={beanShareLoading}
                                onClick={(e) => sendBeanShare(e)}
                                color={colors.lightGreen}
                                mb="12px"
                                fw="600"
                                fs="18px"
                            >
                                {beanShareLoading
                                    ? "Sending bean link..."
                                    : "Send bean link"}
                            </DeleteButton>
                            {beanSharedList.map((item, index) => (
                                <BeanSharedNumber key={index}>
                                    {item.number} {item.message}
                                </BeanSharedNumber>
                            ))}
                        </>
                    )}
                </ItemExpand>
            )}
            <ItemExpand
                open={
                    authenticatedUser.number === openedItem
                        ? usersCurrentBean
                            ? "400px"
                            : "100px"
                        : "48px"
                }
                key={authenticatedUser.number}
                onClick={() => {
                    if (openedItem !== authenticatedUser.number) {
                        setOpenedItem(authenticatedUser.number)
                    } else {
                        setOpenedItem("")
                    }
                }}
                bg={usersCurrentBean ? colors.cheese : colors.lightGray}
                disabled={usersCurrentBean}
            >
                <Flex justifyContent="space-between">
                    <Text margin="0 6px 0 0">{authenticatedUser.name}</Text>
                    <Text m="0">{formatNumber(authenticatedUser.number)}</Text>
                </Flex>
                {authenticatedUser.number === openedItem &&
                    usersCurrentBean && (
                        <TextArea
                            className="fade-in"
                            height="400px"
                            color={colors.wordsNotChosen}
                            fontSize="14px"
                            backgroundColor={colors.veryVeryLightGray}
                            defaultValue={usersCurrentBean}
                        ></TextArea>
                    )}
                {authenticatedUser.number === openedItem && !usersCurrentBean && (
                    <Text textAlign="center" className="fade-in">
                        No bean! :(
                    </Text>
                )}
            </ItemExpand>
            <Text
                m="14px 0 6px 0"
                color={colors.wordsChosen}
                fontSize="20px"
                fontWeight="500"
            >
                Friend's active beans
            </Text>
            {Object.keys(friendsBeans).map((friendsNumber) => (
                <ItemExpand
                    open={
                        friendsNumber === openedItem
                            ? friendsBeans[friendsNumber].bean
                                ? "400px"
                                : "100px"
                            : "48px"
                    }
                    key={friendsNumber}
                    onClick={() => {
                        if (openedItem !== friendsNumber) {
                            setOpenedItem(friendsNumber)
                        } else {
                            setOpenedItem("")
                        }
                    }}
                    bg={
                        friendsBeans[friendsNumber].bean
                            ? colors.cheese
                            : colors.lightGray
                    }
                    disabled={friendsBeans[friendsNumber].bean}
                >
                    <Flex justifyContent="space-between">
                        <Text margin="0 6px 0 0">
                            {friendsBeans[friendsNumber].name}
                        </Text>
                        <Text m="0">{formatNumber(friendsNumber)}</Text>
                    </Flex>
                    {friendsNumber === openedItem &&
                        friendsBeans[friendsNumber].bean && (
                            <TextArea
                                className="fade-in"
                                height="400px"
                                color={colors.wordsNotChosen}
                                fontSize="14px"
                                backgroundColor={colors.veryVeryLightGray}
                            >
                                {friendsBeans[friendsNumber].bean}
                            </TextArea>
                        )}
                    {friendsNumber === openedItem &&
                        !friendsBeans[friendsNumber].bean && (
                            <Text textAlign="center" className="fade-in">
                                No bean! :(
                            </Text>
                        )}
                </ItemExpand>
            ))}
        </Flex>
    )
}

export default BeanPage

const ItemExpand = styled(Flex)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${({ opened }) =>
        opened ? colors.buttonBackgroundChosen : colors.buttonBackground};
    padding: 12px;
    margin: 8px 0;
    flex-direction: column;
    height: ${({ open }) => (open ? open : "48px")};
    transition: all 0.3s ease;
    ${({ bg }) => (bg ? `background-color: ${bg};` : "")}
    overflow: hidden;

    &:hover {
        cursor: pointer;
        background-color: ${({ bg }) =>
            bg ? bg : colors.buttonBackgroundChosen};
    }
`

const StyledTextArea = styled(TextArea)`
    transition: height 0.3s ease;
    resize: none;
`

const BeanSharedNumber = styled(Flex)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${colors.buttonBackground};
    color: ${colors.wordsNotChosen};
    font-size: 14px;
    padding: 8px;
    margin: 6px 0;
    flex-direction: column;
    transition: all 0.3s ease;
`

const DeleteButton = styled(Button)`
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;
    background-color: ${colors.buttonBackground};
    margin-top: ${({ mt }) => (mt ? mt : "16px")};
    color: ${({ color }) => (color ? color : colors.errorRed)};
    padding: 6px 12px;
    ${({ fw }) => (fw ? `font-weight: ${fw};` : "")}
    ${({ fs }) => (fs ? `font-size: ${fs};` : "")}
    ${({ disabled }) => disabled && `opacity: 0.5; pointer-events: none;`}

    &:hover {
        cursor: pointer;
        background-color: ${colors.buttonBackgroundChosen};
    }
`
