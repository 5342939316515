import React, { useEffect, useState } from "react"
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button } from "../components"
import styled from "styled-components"
import colors from "../colors"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"

function CommandPage() {
    const {
        authenticatedUser,
        setAuthenticatedUser,
        loginFailed,
        inVerifyMode,
        loadingInitial,
    } = useAuth()

    console.log(authenticatedUser, "HGELLO")

    return (
        <Flex flexDirection="column">
            {authenticatedUser ? (
                <Link to="/bean">Click here to see beans</Link>
            ) : (
                <Link to="/">Click here to login</Link>
            )}
            <Text fontSize="16px">
                'Cheese' command Here's a list of the commands:
            </Text>
            <Text fontSize="14px">
                1. '<Span fontWeight="600">Join (your name)</Span>' like '
                <Span fontWeight="600">Join Evan Fisher</Span>' - When you are
                invited this will create your account. Type in something your
                friends will recognize or else they might think it's spam.
            </Text>
            <Text fontSize="14px">
                2. '<Span fontWeight="600">Update name (your name)</Span>' like
                '<Span fontWeight="600">Update name Hannah Hiler</Span>' - To
                update your name
            </Text>
            <Text fontSize="14px">
                3. '<Span fontWeight="600">Add friend (persons number)</Span>'
                like '<Span fontWeight="600">Add friend (512)123-4567</Span>' -
                To send a friend request
            </Text>
            <Text fontSize="14px">
                4. '<Span fontWeight="600">Accept</Span>' - This accepts the
                most recent friend request sent to you
            </Text>
            <Text fontSize="14px">
                5. '<Span fontWeight="600">Accept friend (their number)</Span>'
                like '<Span fontWeight="600">Accept friend (512)123-4567</Span>'
                - To accept a specific friend request
            </Text>
            <Text fontSize="14px">
                6. '<Span fontWeight="600">Deny</Span>' - This denies the most
                recent friend request you've received
            </Text>
            <Text fontSize="14px">
                7. '<Span fontWeight="600">Deny friend (their number)</Span>'
                like '<Span fontWeight="600">Deny friend (512)123-4567</Span>' -
                To deny a specific friend request you've received
            </Text>
            <Text fontSize="14px">
                8. '
                <Span fontWeight="600">
                    Delete friend request (their number)
                </Span>
                ' like '
                <Span fontWeight="600">
                    Delete friend request (512)123-4567
                </Span>
                ' - To delete a friend request you've sent
            </Text>
            <Text fontSize="14px">
                9. '<Span fontWeight="600">Delete friend (their number)</Span>'
                like '<Span fontWeight="600">Delete friend (512)123-4567</Span>'
                - To delete a friend
            </Text>
            <Text fontSize="14px">
                10. '<Span fontWeight="600">Create bean (your update)</Span>`
                like '
                <Span fontWeight="600">
                    Create bean My friend got a new puppy named Cheddar Cheese
                    and it's so freakin cute and……
                </Span>
                ' - To send in your update for the two week period
            </Text>
            <Text fontSize="14px">
                11. '<Span fontWeight="600">Update bean (your new bean)</Span>'
                like '
                <Span fontWeight="600">
                    Update bean I found a $100 under my cheddar on the counter!
                    Then I found…
                </Span>
                ' - To update your bean
            </Text>
            <Text fontSize="14px">
                12. '<Span fontWeight="600">See bean</Span>' - To see your
                current bean{" "}
            </Text>
            <Text fontSize="14px">
                13. '<Span fontWeight="600">Check friend requests</Span>' - To
                see your current pending friend requests received and sent
            </Text>
            <Text fontSize="14px">
                {" "}
                14. '<Span fontWeight="600">See friends</Span>' - To see a list
                of your friends{" "}
            </Text>
            <Text fontSize="14px">
                15. '<Span fontWeight="600">Delete friend (their number)</Span>'
                like '<Span fontWeight="600">Delete friend (512)123-4567</Span>'
                - To delete a friend
            </Text>
            <Text fontSize="14px">
                16. '<Span fontWeight="600">Cheese</Span>' - Sends a link to
                this info
            </Text>
        </Flex>
    )
}

export default CommandPage
