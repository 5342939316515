import React, { useEffect, useState } from "react"
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import { Flex, Text, Input, Label, Span, Box, Button, Img } from "../components"
import styled from "styled-components"
import colors from "../colors"
import axios from "../axios"
import { useAuth } from "../context/AuthContext"
import BeanPage from "./BeanPage"
import FriendPage from "./FriendPage"
import NamePage from "./NamePage"

function Dashboard() {
    const [tab, setTab] = useState("friends")
    const { authenticatedUser } = useAuth()
    return (
        <Flex flexDirection="column" maxWidth="700px">
            <Flex
                justifyContent="center"
                alignItems="center"
                mb="12px"
                mt="12px"
                flexDirection="column"
            >
                <Flex
                    borderRadius="25px"
                    bg="#FDCE95"
                    padding="10px"
                    alignItems="center"
                    mt="10px"
                    mb="25px"
                >
                    <Text
                        fontWeight="600"
                        mr="6px"
                        mt="0"
                        mb="0"
                        textAlign="bottom"
                    >
                        Cheesy Bean
                    </Text>
                    <Img src="./cheesy-bean.svg" heihgt="100%" width="25px" />
                </Flex>
                {authenticatedUser.name && (
                    <NetSlider
                        onClick={() => {
                            console.log(tab, "TAB")
                            tab === "friends"
                                ? setTab("beans")
                                : setTab("friends")
                        }}
                    >
                        <ChartSliderWord chosen={tab === "friends"}>
                            Friends
                        </ChartSliderWord>
                        <ChartSliderButton tab={tab} />
                        <ChartSliderWord chosen={tab === "beans"}>
                            Beans
                        </ChartSliderWord>
                    </NetSlider>
                )}
            </Flex>
            {!authenticatedUser.name && <NamePage />}
            {tab === "friends" && authenticatedUser.name && <FriendPage />}
            {tab === "beans" && authenticatedUser.name && <BeanPage />}
        </Flex>
    )
}

const ChartSliderButton = styled(Box)`
    position: absolute;
    background-color: ${colors.buttonBackgroundChosen};
    width: 140px;
    padding: 0.25rem 0.5rem;
    text-align: center;
    left: ${({ tab }) => (tab === "friends" ? "3px" : "136px")};
    height: 30px;
    transition: left 0.3s steps(3);
    box-shadow: inset -1px -1px #ffffff, inset 0px 0px #0a0a0a,
        inset -2px -2px #dfdfdf, inset 2px 2px #808080;
`

const ChartSliderWord = styled(Text)`
    font-size: 18px;
    padding: 0.25rem 0.75rem;
    font-weight: ${({ chosen }) => (chosen ? "500" : "400")};
    color: ${({ chosen }) =>
        chosen ? colors.wordsChosen : colors.wordsNotChosen};
    z-index: 2;
`

const NetSlider = styled(Flex)`
    position: relative;
    margin-top: 10px;
    background-color: ${colors.buttonBackground};
    width: 280px;
    padding: 0.25rem;
    height: 34px;
    align-items: center;
    justify-content: space-around;
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
        inset -2px -2px #808080, inset 2px 2px #dfdfdf;

    &:hover {
        cursor: pointer;
    }
`

export default Dashboard
